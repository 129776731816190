import AppRouter from "./routes/Routes";

function App() {
  // hello
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
